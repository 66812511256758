import { Notification } from 'grommet';
import { ReactNode, useEffect, useState } from 'react';

import { CurrentErrorContext } from 'components/CurrentErrorContext';

const ERROR_TIMEOUT = 5000;

export const ErrorContext = ({ children }: { children: ReactNode }) => {
    const currentErrorState = useState('');
    const [currentError, setCurrentError] = currentErrorState;
    useEffect(() => {
        const handler = setTimeout(() => {
            if (currentError) {
                setCurrentError('');
            }
        }, ERROR_TIMEOUT);
        return () => clearTimeout(handler);
    }, [currentError, setCurrentError]);
    return (
        <CurrentErrorContext.Provider value={currentErrorState}>
            {!!currentError && (
                <Notification
                    toast
                    global
                    status="critical"
                    time={ERROR_TIMEOUT}
                    title="Error"
                    message={currentError}
                />
            )}
            {children}
        </CurrentErrorContext.Provider>
    );
};
