import { API } from 'aws-amplify';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';

import ChelseaGolfing from 'assets/chelseaGolfing.png';
import Bowloween2022 from 'assets/events/bowloween-2022.svg';
import Bowloween from 'assets/events/bowloween.svg';
import ComedyShow from 'assets/events/comedy-show.jpg';
import GolfOuting from 'assets/events/golf-outing.jpg';
import { CHARITY_API_NAME } from 'services/constants';
import { useHandleError } from 'services/errors';

interface CharityEventResponse {
    charity_event_id: string;
    event_name: string;
    event_description: string;
    event_start: string;
    location: string;
    address: {
        address_line_1: string;
        address_line_2: string;
        city: string;
        state: string;
        zipcode: string;
    };
    registration_start: string;
    registration_end: string;
    packages: {
        package_id: string;
        package_name: string;
        package_price: number;
        package_description: string;
        spots_remaining: number;
        max_package_count_per_user: number;
    }[];
}

export const fetchCharityEvents = async () =>
    API.get(CHARITY_API_NAME, '', {}) as Promise<CharityEventResponse[]>;

export interface CharityEvent {
    eventId: string;
    eventTitle: string;
    description: string;
    eventDate: string;
    image?: string;
    location: string;
    address: string;
    registrationStart: string;
    registrationEnd: string;
    formImage?: string;
    packages: {
        packageId: string;
        title: string;
        name: string;
        cost: number;
        description: string;
        remaining: number;
        maxPackageCount: number;
    }[];
}

const EventImageMap: Record<string, string> = {
    'Golf Outing': GolfOuting,
    '2023 Golf Outing': GolfOuting,
    '2024 Golf Outing': GolfOuting,
    'Bowloween Event': Bowloween2022,
    '2023 Bowloween': Bowloween,
    '2024 Bowloween': Bowloween,
    'Comedy Show': ComedyShow,
    '2025 Comedy Show': ComedyShow,
};

const FormImageMap: Record<string, string> = {
    'Golf Outing': ChelseaGolfing,
};

const mapCharityEvent = (event: CharityEventResponse): CharityEvent => ({
    eventId: event.charity_event_id,
    eventTitle: event.event_name,
    eventDate: event.event_start,
    description: event.event_description,
    image: EventImageMap[event.event_name],
    location: event.location,
    address: `${event.address.address_line_1}${
        event.address.address_line_2 ? ` ${event.address.address_line_2}` : ''
    }, ${event.address.city}, ${event.address.state} ${event.address.zipcode}`,
    registrationStart: event.registration_start,
    registrationEnd: event.registration_end,
    formImage: FormImageMap[event.event_name],
    packages: event.packages.map((eventPackage) => ({
        packageId: eventPackage.package_id,
        title: eventPackage.package_name,
        name: eventPackage.package_name,
        cost: eventPackage.package_price,
        description: eventPackage.package_description,
        remaining: eventPackage.spots_remaining,
        maxPackageCount: eventPackage.max_package_count_per_user,
    })),
});

export const useCharityEvents = () => {
    const [events, setEvents] = useState<CharityEvent[]>();
    const handleError = useHandleError();
    useEffect(() => {
        (async () => {
            try {
                const response = await fetchCharityEvents();
                setEvents(
                    response
                        .map(mapCharityEvent)
                        .sort((a, b) => dayjs(b.eventDate).diff(a.eventDate)),
                );
            } catch (err) {
                handleError('Failed to retrieve event information.', err as Error);
            }
        })();
    }, [handleError]);
    return events;
};

export const fetchCharityEventDetails = async (eventId: string) =>
    API.get(CHARITY_API_NAME, `/${eventId}`, {}) as Promise<CharityEventResponse>;

export const useCharityEventDetails = (eventId?: string) => {
    const [event, setEvent] = useState<CharityEvent>();
    const handleError = useHandleError();
    useEffect(() => {
        (async () => {
            try {
                if (eventId) {
                    const response = await fetchCharityEventDetails(eventId);
                    setEvent(mapCharityEvent(response));
                }
            } catch (err) {
                handleError('Failed to retrieve event information.', err as Error);
            }
        })();
    }, [eventId, handleError]);
    return event;
};

interface EventRegistrationPayload {
    eventId: string;
    registeredPackages: {
        package_id: string;
        qty: string;
        details: {
            firstName: string;
            lastName?: string;
            email: string;
            phone?: string;
            shirtSize?: string;
        }[];
    }[];
}

export const initiateEventRegistration = async (payload: EventRegistrationPayload) =>
    API.post(CHARITY_API_NAME, '/register', {
        body: {
            event_id: payload.eventId,
            registered_packages: payload.registeredPackages,
        },
    }) as Promise<{ client_secret: string }>;
