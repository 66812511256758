import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import {
    Box,
    Heading,
    Paragraph,
    ResponsiveContext,
    Table,
    TableBody,
    TableCell,
    TableHeader,
    TableRow,
    Text,
} from 'grommet';
import { useContext } from 'react';

import { EventImageGallery } from 'components/shared/EventImageGallery';

dayjs.extend(localizedFormat);

interface CausesSupportedEntry {
    name: string;
    date: string;
    amount: number;
    use: string;
}

const CausesSupportedData: CausesSupportedEntry[] = [
    {
        name: 'Make-A-Wish',
        date: '9/17/2024',
        amount: 10000,
        use: 'to send a local girl with brain cancer (and her family) to Hawaii, and to sponsor a shopping spree for a local boy with brain cancer',
    },
    {
        name: "Children's Hospital Pittsburgh Foundation",
        date: '8/1/2024',
        amount: 20000,
        use: 'to fund research position for cancer center',
    },
    {
        name: 'Scholarship To Graduating Senior',
        date: '5/20/24',
        amount: 3000,
        use: 'presented to a North Hills graduate with similar needs as Chelsea',
    },
    {
        name: 'Make-A-Wish',
        date: '9/26/2023',
        amount: 5000,
        use: 'to send a local boy with brain cancer (and his family) to Hawaii',
    },
    {
        name: "Children's Hospital Pittsburgh Foundation",
        date: '8/8/2023',
        amount: 20000,
        use: 'to fund research position for cancer center',
    },
    {
        name: 'Local Family',
        date: '04/30/2023',
        amount: 500,
        use: 'to help with costs for a young man with brain cancer',
    },
    {
        name: 'Local Family',
        date: '10/29/2022',
        amount: 500,
        use: 'to help with medical costs for girl with cancer',
    },
    {
        name: "UPMC Children's Hospital Foundation",
        date: '10/10/2022',
        amount: 2750,
        use: 'for the Hematology/Oncology Clinic snack cart',
    },
    {
        name: 'Make-A-Wish',
        date: '10/06/2022',
        amount: 5000,
        use: 'to send a little boy with brain cancer to Lego Land',
    },
    {
        name: "St. Jude Children's Research Hospital",
        date: '09/23/2022',
        amount: 2000,
        use: '(matched to total $4,000.00) to the Pgh walk/run',
    },
];

const Columns: {
    label: string;
    property: keyof CausesSupportedEntry;
    format?: (data: CausesSupportedEntry) => string | undefined;
}[] = [
    { label: 'Cause', property: 'name' },
    {
        label: 'Date',
        property: 'date',
        format: (data) => (data.date ? dayjs(data.date).format('L') : undefined),
    },
    {
        label: 'Amount',
        property: 'amount',
        format: (data) =>
            data.amount?.toLocaleString(undefined, {
                style: 'currency',
                currency: 'USD',
            }),
    },
];

export const CausesSupported = () => {
    const size = useContext(ResponsiveContext);
    const isMobile = size === 'small';
    return (
        <Box alignSelf="center" width="xxlarge" pad={{ bottom: 'medium' }}>
            <Heading level={2}>CAUSES SUPPORTED</Heading>
            <Box direction={!isMobile ? 'row' : 'column'} gap="medium">
                <Box>
                    <Heading level={3}>Contributions</Heading>
                    <Table margin={{ bottom: 'small' }}>
                        <TableHeader>
                            <TableRow>
                                {Columns.map((c) => (
                                    <TableCell
                                        key={c.property}
                                        scope="col"
                                        border={{ color: 'gray', side: 'bottom' }}
                                    >
                                        <Text weight="bold">{c.label}</Text>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {CausesSupportedData.map((cause, i) => (
                                <TableRow key={`${cause.name}${cause.date}`}>
                                    {Columns.map((c, j) => (
                                        <TableCell
                                            key={c.property}
                                            scope="col"
                                            border={
                                                i !== CausesSupportedData.length - 1
                                                    ? { color: 'gray', side: 'bottom' }
                                                    : undefined
                                            }
                                        >
                                            <Text>{c.format?.(cause) ?? cause[c.property]}</Text>
                                            {j === 0 && (
                                                <Box>
                                                    <Text>{cause.use}</Text>
                                                </Box>
                                            )}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <EventImageGallery event={{ eventTitle: 'Causes Supported' }} />
                </Box>
                <Box
                    width={!isMobile ? '30%' : undefined}
                    background="gray"
                    pad={{ left: 'medium', right: 'medium' }}
                >
                    <Heading level={3}>Where The Money Goes</Heading>
                    <Text weight="bold">Children&apos;s Hospital Pittsburgh</Text>
                    <Paragraph fill>
                        Chelsea was treated at CHP from June 2007 until she passed July 2019. She
                        spent countless hours in the Hematology Oncology Clinic, along with other
                        departments such as radiation (for MRIs), and doing therapies and testing.
                        The goal of the foundation is to work with the HemOnc clinic to determine
                        what would be a best fit items/services to donate.
                    </Paragraph>
                    <Text weight="bold">Make-a-Wish</Text>
                    <Paragraph fill>
                        Chelsea was granted her wish of a bedroom makeover, the fall of 2007. The
                        foundation will donate the funds to grant wishes for other children.
                    </Paragraph>
                    <Text weight="bold">St. Jude Children&apos;s Research Hospital</Text>
                    <Paragraph fill>
                        Chelsea lived at St. Jude with her mom from July 2018 - February 2019.
                        During this time, she received all medical care and housing free of charge.
                        The family was also given food/necessities allowance each week. In addition
                        to basic needs, St. Jude offered numerous events sponsored by many different
                        Companies and Organizations. The goal of the foundation is to sponsor an
                        event or events, geared towards the young adults being treated there.
                    </Paragraph>
                    <Text weight="bold">Scholarship for graduating senior</Text>
                    <Paragraph fill>
                        The foundation will sponsor at least one scholarship per academic year to a
                        High School Senior, with at least one of the following criteria: Plan to
                        attend Indiana University of Pennsylvania (IUP), Plan to major/minor in
                        Interior Design or Theater, Brain cancer diagnosis, or the need for
                        Disability Support Services (DSS).
                    </Paragraph>
                    <Text weight="bold">Brain Cancer Research</Text>
                    <Paragraph fill>
                        The foundation will donate to a hospital or other organization that is
                        actively doing research to help find a cure for pediatric brain cancer.
                        Focus will be on research for Medulloblastoma when possible.
                    </Paragraph>
                </Box>
            </Box>
        </Box>
    );
};
